import React from 'react'
import Link from 'next/link'

import Text from '@components/common/Text'
import Button from '@components/common/Button'

import css from './404.module.scss'

const Page404 = () => {
  return (
    <div className={css.container}>
      <img src="/images/error-404.svg"/>
      <Text variant="h4" align="center">Página no encontrada</Text>
      <Link href="/">
        <a><Button>Volver a la página de inicio</Button></a>
      </Link> 
    </div>
  )
}

export default Page404

